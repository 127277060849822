import img1 from '../images/items/1.jpeg'
import img2 from '../images/items/2.jpeg'
import img3 from '../images/items/3.jpeg'
import img4 from '../images/items/4.jpeg'
import img5 from '../images/items/5.jpeg'
import img6 from '../images/items/6.jpeg'
import img7 from '../images/items/7.jpeg'
import img8 from '../images/items/8.jpeg'
import img9 from '../images/items/9.jpeg'
import img10 from '../images/items/10.jpeg'

const dataMontono = [
    {
        img: img1,
        title: 'EverMon item 1'
    },
    {
        img: img2,
        title: 'EverMon item 2'
    },
    {
        img: img3,
        title: 'EverMon item 3'
    },
    {
        img: img4,
        title: 'EverMon item 4'
    },
    {
        img: img5,
        title: 'EverMon item 5'
    },
    {
        img: img6,
        title: 'EverMon item 6'
    },
    {
        img: img7,
        title: 'EverMon item 7'
    },
    {
        img: img8,
        title: 'EverMon item 8'
    },
    {
        img: img9,
        title: 'EverMon item 3'
    },
    {
        img: img10,
        title: 'EverMon item 4'
    },
]

export default dataMontono;