const dataRoadMap = [
    {
        time: "Aug 2022",
        title: "White List Invitation",
        desc: "",
        title2: "",
        desc2: "",
        class: "left"
    },
    {
        time: "Sep 2022",
        title: "Summoner NFT public sales",
        desc: "",
        title2: "",
        desc2: "",
        class: "right mt-120"
    },
    {
        time: "Sep 2022",
        title: "EverMon <Summoner> demo version for White List VIP",
        desc: "",
        title2: "",
        desc2: "",
        class: "left mt--23"
    },
    {
        time: "Dec 2022",
        title: "EverMon <Summoner> open beta",
        desc: "- Single Player, Multi-player Map\n- Basic Weapon Farming\n- Token Reward- Player Ranking",
        title2: "",
        desc2: "",
        class: "right mt-120"
    },
    {
        time: "Mar 2023",
        title: "EverMon <Monster> demo version\nEverMon <Summoner> V1.0",
        desc: "- Player Duel System and Reward\n- Equipment NFT Faming\n- Soulbind Monster",
        title2: "",
        desc2: "",
        class: "left mt--23"
    },
    {
        time: "Jun 2023",
        title: "EverMon <Monster> open beta\nEverMon NFT trading platform",
        desc: "- Listing and Trading of all EverMon NFT\n- Support ETH & Token\n- Token Reward- Player Ranking\n(EverMon NFT can be traded in other NFT platform before EverMon NFT trading platform launched)",
        title2: "Introducing land with in-game creator function",
        desc2: "",
        class: "right mt-120"
    },
    {
        time: "Sep 2023",
        title: "EverMon <Monster> V1.0",
        desc: "- Story Mode and Duel mode\n- Daily & Monthly Event\n- Daily & Weekly mission\n- Player Ranking\n- Dungeon Farming for Equipment NFT",
        title2: "EverMon <Summoner> V2.0",
        desc2: "- Arena for PVP\n- In-game Arena Design and Decoration\n- Advanced Skill system\n- Monster breeding",
        class: "left mt--23"
    },
    {
        time: "Dec 2023 ",
        title: "EverMon <Arena> demo version\nIntegrating EverMon NFT trading platform to EverMon Games",
        desc: "",
        title2: "",
        desc2: "",
        class: "right mt-300"
    },
    {
        time: "Mar 2024",
        title: "EverMon <Arena> open beta",
        desc: "",
        title2: "",
        desc2: "",
        class: "left mt--23"
    },
    {
        time: "Jun 2024",
        title: "EverMon <Arena> V1.0",
        desc: "- Rule Set 1.0\n- EverMon <Arena> card deck",
        title2: "",
        desc2: "",
        class: "right mt-120"
    },   
    {
        time: "2024 onward ",
        title: "Game balancing and New game content for existing game\nNew Game Development: EverMon <Farm>, EverMon <Team>, EverMon <Kid>",
        desc: "",
        title2: "",
        desc2: "",
        class: "left mt--23"
    },  
]
export default dataRoadMap;