import img1 from '../images/icon/icon.png';
import img2 from '../images/icon/icon.png';
import img3 from '../images/icon/icon.png';
import img4 from '../images/icon/icon.png';

const dataPortfolio = [
    {
        img: img1,
        step:'EverMon Community',
        title : 'WE ARE EVERMON',
        desc: 'EverMon happened in the land of Kazylema - a fantasy land that being the center of various elemental dimensions. Hero in EverMon get the power to control lamens which can summon monsters from these dimensions to fight alongside.'
    },
    {
        img: img2,
        step:'EverMon Community',
        title : 'EVERMON [ MONSTER ]',
        desc: 'After hero soul-binding a number of monsters, they backed to Kazylema to train their Monster team against powerful mythical creatures, and eventually defeat the Greatest Devil Summoner who has destroyed the peace of Kazylema for over a thousand years.'
    },
    {
        img: img3,
        step:'EverMon Community',
        title : 'EVERMON [ ARENA ]',
        desc: 'Summoners representing different position fighting each other day by day. After year long fight, a group of powerful summoner formed a league and regulating fights among summoners, they called the league EverMon <Arena>.'
    },
    {
        img: img4,
        step:'EverMon Community',
        title : 'SOULBIND YOUR MONSTER HERE',
        desc: 'Being the first game to be launched in the series, EverMon <Summoner> is a fast pace action RPG game, in which player can finish a game within minutes to achieve a certain mission of the map.'
    },
]

export default dataPortfolio;