import { React , useState } from 'react';
import { Link } from 'react-router-dom'

import item from '../../assets/data/item';

const About = () => {

    const [data] = useState(
        {
            subtitle: 'EverMon',
            title: 'BUNDLE WITH SERIES OF TRADITIONAL GAMES',
            desc1: 'Players are able to play all EverMon game includes Action rpg, Card game arena, Idle game, Moba, Shooting, Party game and Farming game with 1 NFT, this not only enhance the Play2Earn feature, but also giving our Token and NFT a higher implicit and potential value.',
        }
    )

    return (
        <section className="tf-section section-about">
            <div className="container">
                <div className="row reverse">
                    <div className="col-xl-7 col-md-12">
                        <div className="group-image">
                            <div className="left">
                                <div className="item"><img src={item.item32} alt="EverMon" /></div>
                            </div>
                            <div className="right">
                            <div className="item"><img src={item.item31} alt="EverMon" /></div>
                                <div className="item"><img src={item.item30} alt="EverMon" /></div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-md-12">
                        <div className="block-text pt-12">
                            <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">{data.subtitle}</h5>
                            <h3 className="title mb-58" data-aos="fade-up" data-aos-duration="1000">{data.title}</h3>
                            <p className="fs-21 mb-33" data-aos="fade-up" data-aos-duration="1000">{data.desc1}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;