import React , { useState } from 'react';
import { Link } from 'react-router-dom'

const Action = () => {

    const [data] = useState(
        {
            title: 'EverMon',
            p:"Learn More About Us"
        }
    )

    return (
        <section className="tf-section action">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="action__body" data-aos="fade-up">
                            <div className="block-text">
                                <h3 className="mb-13">{data.title}</h3>
                                <p>{data.p}</p>
                            </div>
                            <a href="https://drive.google.com/file/d/154z82kQiP_OPw4gHfe4tU5cMlEtSnxET/view?usp=sharing" className="btn-action style-1" target="_blank" rel="noreferrer">White Paper</a>
                            <a href="https://drive.google.com/file/d/17hqCizAa-iKFGYx-fTR-clsmAKJW86jL/view?usp=sharing" className="btn-action style-1" target="_blank" rel="noreferrer">Company Deck</a>
                            <a href="https://drive.google.com/file/d/1xI2ERF4YeqFcUYXOwAlSp7DZ2Dvl7Iok/view?usp=sharing" className="btn-action style-1" target="_blank" rel="noreferrer">Tokenomics</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Action;