import React , { useState ,useEffect } from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';

import logo from '../../assets/data/logo';

const Footer = () => {

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <>
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="footer__body">
                      <Link to="/">
                        <img src={logo} alt="EverMon" data-aos="fade-down" />
                      </Link>
                        
                        <p className="desc fs-18" data-aos="fade-up">
                        EverMon target to build a revolutionary Game-Fi and Social-Fi Eco-system, by creating a series of game with different game play style, to attract players who attached to different game type varying from Action rpg, Card game arena, Idle game, Moba, Shooting, Party game, Farming game...etc, to join our EverMon community.
                        </p>
                        <ul className="social">
                            <li data-aos="fade-up" data-aos-duration="1000"><a href="https://www.facebook.com/EverMon-107808518721175" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                            <li data-aos="fade-up" data-aos-duration="1200"><a href="https://twitter.com/EverMonGames" target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a></li>
                            <li data-aos="fade-up" data-aos-duration="1600"><a href="https://www.youtube.com/channel/UC5onyuU1HN3KwuZ30sB3jAQ" target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i></a></li>
                        </ul>
                    </div>
                    <div className="footer_bottom">
                        <p className="fs-16">Copyright © 2022, EverMon</p>
                        <ul>
                            <li><Link to="#">Terms & Condition</Link></li>
                            <li><Link to="#">Privacy Policy</Link></li>
                            <li><Link to="#">Cookie Policy</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        {
            isVisible && 
            <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
        }</>
        
    );
}

export default Footer;