const item = {
    item1 : require('../images/items/1.jpeg'),
    item2 : require('../images/items/2.jpeg'),
    item3 : require('../images/items/3.jpeg'),
    item4 : require('../images/items/4.jpeg'),
    item5 : require('../images/items/5.jpeg'),
    item6 : require('../images/items/6.jpeg'),
    item7 : require('../images/items/7.jpeg'),
    item8 : require('../images/items/8.jpeg'),
    item9 : require('../images/items/9.jpeg'),
    item10 : require('../images/items/10.jpeg'),
    item30 : require('../images/items/30.png'),
    item31 : require('../images/items/31.png'),
    item32 : require('../images/items/32.png'),
    technology : require('../images/items/technology.png'),
}

export default item;