import React from 'react';
import { Link } from 'react-router-dom'

const Team = (props) => {

    const data = props.data;

    function NewlineText(props) {
        const text = props.text;
        const newText = text.split('\n').map(str => <p>{str}</p>);
        
        return newText;
      }

    return (
        <section className="tf-section team" id='team'>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center">
                            <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000"><span>Team</span></h1>
                            <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">Team Members</h5>
                            <h3 className="title mb-28" data-aos="fade-up" data-aos-duration="1000">Our Amazing Team <br /> Members</h3>
                        </div>
                    </div>
                </div>
                <div className="row mt-53">
                        
                    {
                                data.map((data,index) => (
                                    <div key={index} className="col-xl-4 col-md-6" data-aos="fade-in">
                                        <div className="team-box">
                                        <div className="img-team"> <img src={data.img} alt="EverMon" /> </div>
                                        <div className="team-info">
                                            <Link to="/team" className="h5">{data.name}</Link>
                                            <p className="fs-16"><NewlineText text={data.position} /></p>
                                        </div>
                                    </div>
                                </div>
                                ))
                            }
                </div>    
                
            </div>
        </section> 
    );
}

export default Team;