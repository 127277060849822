import React from 'react';
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import dataCard from '../assets/data/data-card';
import dataMontono from '../assets/data/data-montono';
import dataPortfolio from '../assets/data/data-portfolio';
import dataRoadMap from '../assets/data/data-roadmap';
import dataTeam from '../assets/data/data-team';
import About from '../components/layouts/About';
import Action from '../components/layouts/Action';
import Montono from '../components/layouts/Montono';
import Portfolio from '../components/layouts/Portfolio';
import RoadMap from '../components/layouts/RoadMap';
import Speciality from '../components/layouts/Speciality';
import Team from '../components/layouts/Team';
import SliderOne from '../components/slider/SliderOne';

const Home01 = () => {
    return (
        <div>
            <Header />
            <SliderOne />
            <About />
            <Speciality data={dataCard} />
            <Portfolio data={dataPortfolio} />
            <Montono data={dataMontono} />
            <RoadMap data={dataRoadMap} />
            <Team data={dataTeam} />
            <Action />
            <Footer />
        </div>
    );
}

export default Home01;