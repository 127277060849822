import img1 from '../images/team/team-01.jpg'
import img2 from '../images/team/team-02.jpg'
import img3 from '../images/team/team-03.jpg'
import img4 from '../images/team/team-04.jpg'
import img5 from '../images/team/team-05.jpg'
import img6 from '../images/team/team-06.jpg'
import img7 from '../images/team/team-07.jpg'
import img8 from '../images/team/team-08.jpg'
import img9 from '../images/team/team-09.jpg'
import img10 from '../images/team/team-10.jpg'
import img11 from '../images/team/team-11.jpg'
import img12 from '../images/team/team-12.jpg'

const dataTeam = [
    {
        img: img1,
        name:'Danny Ngai',
        position: 'C.E.O.,\nGame Designer'
    },
    {
        img: img2,
        name:'Tin Hang Wong',
        position: 'C.T.O.,\nGame Designer'
    },
    {
        img: img3,
        name:'Patrick Lee',
        position: 'C.O.O,\nGame Designer'
    },
    {
        img: img4,
        name:'Alanie Leung',
        position: 'Product Director\nBrand Manager'
    },
    {
        img: img5,
        name:'Josh',
        position: 'Project Manager,\nSenior Programmer'
    },
    // {
    //     img: img6,
    //     name:'小石',
    //     position: 'Unity Programmer,\n3D Art Designer'
    // },
    {
        img: img7,
        name:'Tung',
        position: 'System Analyst,\nBackend Programmer'
    },
    {
        img: img8,
        name:'晉德',
        position: '3D\nArt Designer'
    },
    // {
    //     img: img9,
    //     name:'Elaine Ko',
    //     position: 'Computer Art\nDesigner'
    // },
    {
        img: img10,
        name:'Dang',
        position: 'Smart Contract programmer\nBackend Programmer'
    },
    {
        img: img11,
        name:'Phuc',
        position: 'Blockchain\nConsultant'
    },
    // {
    //     img: img12,
    //     name:'Khanh',
    //     position: 'App\nProgrammer'
    // },
]

export default dataTeam;