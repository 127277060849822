import img1 from '../images/icon/hosting.png'
import img2 from '../images/icon/provision.png'
import img3 from '../images/icon/badge.png'
import img4 from '../images/icon/chat.png'

const dataCard = [
    {
        stt: '01',
        title:'Gaming Experience',
        desc: 'Bundle with series of traditional games',
        class: 'mr-28',

        class2: '',
        img: img1
    },
    {
        stt: '02',
        title:'P2E',
        desc: 'NEW Play-to-Earn Model',
        class: 'mt-45',

        class2: 's2',
        img: img2
    },
    {
        stt: '03',
        title:'Game Assets',
        desc: 'NFT and Token are to be shared among different games',
        class: 'mr-28 mt--16',

        class2: 's2-m',
        img: img3
    },
    {
        stt: '04',
        title:'Access Pass',
        desc: 'Play all EverMon games with ONLY 1 NFT',
        class: 'mt-29',

        class2: 's2',
        img: img4
    },
]

export default dataCard;